// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations'
import * as Sentry from '@sentry/nextjs'
import getConfig from 'next/config'

const config = getConfig().publicRuntimeConfig

const SENTRY_DSN = config.SENTRY_DSN || config.NEXT_PUBLIC_SENTRY_DSN
const ENVIRONMENT = config.SENTRY_ENVIRONMENT

const enabled = Boolean(ENVIRONMENT) && ENVIRONMENT !== 'development'

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://824b9acd00f449f283554475ff8303bb@o698378.ingest.sentry.io/5776661',
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  enabled,

  attachStacktrace: true,

  environment: ENVIRONMENT || 'development',
  ignoreErrors: [
    /SKIP_SENTRY/,
    /Non-Error promise rejection captured with keys: currentTarget, isTrusted, target, type/,
  ],
  integrations: [
    // Send specific console errors to sentry
    // https://docs.sentry.io/platforms/javascript/configuration/integrations/plugin/#captureconsole
    enabled &&
      new CaptureConsoleIntegration({
        levels: ['error'],
      }),
  ],
  beforeSend(event, _hint) {
    if (event.logger === 'console') {
      // Only send specific console errors to Sentry
      if (!event.message?.match(/@gamma-app\/y-prosemirror/)) {
        return null
      }
    }

    return event
  },
})

Sentry.setUser({
  isGammaE2E: config.GAMMA_E2E_USER,
})
